import React from "react";
import Page from "../../components/page/page";
import {Card, CardContent, Grid, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {TableBodyCell, TableHeadCell} from "../../components/unit/table";
import flow from "assets/imgs/guide/flow.png"
import oauthUrl from "assets/imgs/guide/oauth-url.png"


function AP10400() {

    return (
        <Page title_description={"Describes the procedure for applying o'care oauth2."}>
            <Grid container justifyContent="left" alignItems="center" sx={{m:2, height:'100%', overflowY: 'scroll'}} >
                <Card variant="outlined" sx={{background:'#FAFAFA', height:'2100px'}}>
                    <CardContent>
                        <Grid container  alignItems="center" justifyContent="left" sx={{mt:1}}>
                        <Typography  gutterBottom variant="h6">
                             ▶︎ 오케어 인증 과정
                        </Typography>
                        </Grid>

                        <Grid container alignItems="center" justifyContent="left">
                            <Grid item>
                                <Typography
                                    fontSize="14px"
                                    gutterBottom
                                >
                                    오케어 제휴서비스를 손 쉽게 이용하기 위한 Oauth2.0 서비스입니다.
                                </Typography>
                                <Typography
                                    fontSize="14px"
                                    gutterBottom
                                >
                                    기본적인 Oauth 절차와 다르게 이미 로그인한 후 제휴서비스를 연계하기 때문에
                                    제휴사는 인증과정을 생략하고 바로 Step2로 진행합니다.
                                </Typography>

                                <Typography
                                    fontSize="14px"
                                    gutterBottom
                                >
                                    다음은 REST API를 사용한 인증 과정을 나타낸 시퀀스 다이어그램입니다. 단계별 안내를 함께 참고합니다.
                                </Typography>

                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent="left">
                            <Grid item>
                                <img src={flow} alt="flow" width={750} height={450}/>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2} alignItems="left" justifyContent="left">
                            <Grid item alignItems="left" justifyContent="left">

                                <Typography
                                    fontSize="16px"
                                    gutterBottom
                                >
                                    ▷ Step 1. 인가코드 받기 (Skip)
                                </Typography>
                                <Typography fontSize="14px" gutterBottom>
                                    1. 서비스 서버가 오케어 인증 서버로 인가코드 받기를 요청합니다.
                                </Typography>
                                <Typography fontSize="14px" gutterBottom>
                                    2. 오케어 인증 서버가 사용자에게 오케어 로그인을 통한 인증을 요청합니다.
                                </Typography>
                                <Typography fontSize="14px" gutterBottom>
                                    3. 사용자가 본인 계정으로 로그인합니다.
                                </Typography>
                                 <Typography fontSize="14px" gutterBottom>
                                    4. 오케어 인증 서버가 사용자에게 동의 화면을 출력하여 인가를 위한 사용자 동의를 요청합니다.
                                 </Typography>
                                <Typography fontSize="14px" gutterBottom>
                                    5. 사용자가 필수 동의 항목, 이 외 원하는 동의 항목에 동의한 뒤 [동의하고 계속하기] 버튼을 누릅니다.
                                </Typography>
                                <Typography fontSize="14px" gutterBottom>
                                    6. 오케어 인증 서버는 서비스 서버의 Redirect URI로 인가 코드를 전달합니다.
                                </Typography>

                                <Typography
                                    fontSize="16px"
                                    gutterBottom
                                >
                                    ▷ Step 2. 토큰 받기
                                </Typography>

                                <Typography fontSize="14px" gutterBottom>
                                    1. 서비스 서버가 오케어 서비스로부터 전달받은 인가 코드로 토큰 받기를 요청합니다.
                                </Typography>
                                <Typography fontSize="14px" gutterBottom>
                                    2. 카카오 인증 서버가 토큰을 발급해 서비스 서버에 전달합니다.
                                </Typography>
                            </Grid>
                        </Grid>


                        <Grid container  alignItems="center" justifyContent="left" sx={{mt:1}}>
                            <Typography variant="h6" gutterBottom>
                                ▶︎ 오케어 인증
                            </Typography>
                        </Grid>

                        <Grid container alignItems="center" justifyContent="left">
                            <Grid item>
                                <Typography
                                    fontSize="14px"
                                    gutterBottom
                                >
                                    Step1에 내부적으로 절차를 수행해서 사용할 수 있는 인가 코드를 건네주기 때문에 설명은 생략합니다.
                                </Typography>
                                <Typography
                                    fontSize="14px"
                                    gutterBottom
                                >
                                    토큰은 필수 파라미터를 포함해서 POST로 요청합니다. 요청 성공 시 응답은 토큰과 토큰 정보를 포함합니다.
                                </Typography>

                                <Typography
                                    fontSize="14px"
                                    gutterBottom
                                >
                                    액세스 토큰으로 사전에 협의된 Scope를 호출할 수 있습니다.
                                </Typography>

                            </Grid>
                        </Grid>

                        <Typography
                            fontSize="16px"
                            gutterBottom

                        >
                            ▷ url 정보
                        </Typography>

                                <img src={oauthUrl} alt="flow" width={490} height={70}/>

                        <Typography
                            fontSize="16px"
                            gutterBottom
                        >
                            ▷ Parameter
                        </Typography>

                        <Typography sx={{ mt: 2, mb:2}} component="div" variant="body2" color="text.secondary" align="center">
                            <Table>
                                <TableHead>
                                    <TableRow sx={{background:'#f7f7f7', borderTopStyle:'solid', borderTopColor:'#fac84b'}}>
                                        <TableHeadCell text={'Name'} width={"100"}/>
                                        <TableHeadCell text={'Type'} width={"100"}/>
                                        <TableHeadCell text={'Description'} width={"300"}/>
                                        <TableHeadCell text={'Required'} width={"100"}/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableBodyCell text={'grant_type'}/>
                                        <TableBodyCell text={'String'}/>
                                        <TableBodyCell text={'authorization_code로 고정'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableBodyCell text={'client_id'}/>
                                        <TableBodyCell text={'String'}/>
                                        <TableBodyCell text={'clients 메뉴에서 확인 가능'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableBodyCell text={'redirect_uri'}/>
                                        <TableBodyCell text={'String'}/>
                                        <TableBodyCell text={'인가 코드가 리다이렉트된 URI'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableBodyCell text={'code'}/>
                                        <TableBodyCell text={'String'}/>
                                        <TableBodyCell text={'인가 코드 받기 요청으로 얻은 인가 코드'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Typography>

                        <Typography
                            fontSize="16px"
                            gutterBottom
                        >
                            ▷ Response
                        </Typography>

                        <Typography sx={{ mt: 2 }} component="div" variant="body2" color="text.secondary" align="center">
                            <Table>
                                <TableHead>
                                    <TableRow sx={{background:'#f7f7f7', borderTopStyle:'solid', borderTopColor:'#fac84b'}}>
                                        <TableHeadCell text={'Name'} width={"100"}/>
                                        <TableHeadCell text={'Type'} width={"100"}/>
                                        <TableHeadCell text={'Description'} width={"300"}/>
                                        <TableHeadCell text={'Required'} width={"100"}/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableBodyCell text={'token_type'}/>
                                        <TableBodyCell text={'String'}/>
                                        <TableBodyCell text={'토큰 타입, bearer로 고정'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableBodyCell text={'access_token'}/>
                                        <TableBodyCell text={'String'}/>
                                        <TableBodyCell text={'사용자 액세스 토큰 값'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableBodyCell text={'expires_in'}/>
                                        <TableBodyCell text={'Integer'}/>
                                        <TableBodyCell text={'액세스 토큰과 ID 토큰의 만료 시간(초)'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableBodyCell text={'refresh_token'}/>
                                        <TableBodyCell text={'String'}/>
                                        <TableBodyCell text={'사용자 리프레시 토큰 값'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableBodyCell text={'refresh_token_expires_in'}/>
                                        <TableBodyCell text={'Integer'}/>
                                        <TableBodyCell text={'리프레시 토큰 만료 시간(초)'}/>
                                        <TableBodyCell text={'O'}/>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Typography>


                    </CardContent>
                </Card>
            </Grid>
        </Page>
    );
}

export default AP10400;


