import React from "react";
import Page from "../../components/page/page";
import {Box} from "@mui/material";


function AP10210() {

    return (
        <>
            <Page title_description={"Only access_token JWT generator within 5 minutes"}>
                <Box component="div" sx={{m:1}}>
                    TODO..
                </Box>
            </Page>
        </>
    );
}

export default AP10210;