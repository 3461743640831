import React from "react";
import AP10010 from "./AP10010";
import AP10020 from "./AP10020";
import AP10030 from "./AP10030";
import AP10040 from "./AP10040";

import AP10100 from "./AP10100";

import AP10210 from "./AP10210";
import AP10220 from "./AP10220";
import AP10230 from "./AP10230";
import AP10240 from "./AP10240";
import AP10300 from "./AP10300";
import AP10400 from "./AP10400";
import AP10500 from "./AP10500";

// 실제 메뉴 호출 시점에 불러 올 수 있도록 lazy loading
// const AP10010 = React.lazy(() => import('./AP10010'));
// const AP10020 = React.lazy(() => import('./AP10020'));
// const AP10100 = React.lazy(() => import('./AP10100'));

export const menuComponents = new Map<string, React.ReactNode>()
menuComponents.set('AP10010', <AP10010/>)
menuComponents.set('AP10020', <AP10020/>)
menuComponents.set('AP10030', <AP10030/>)
menuComponents.set('AP10040', <AP10040/>)
menuComponents.set('AP10100', <AP10100/>)
menuComponents.set('AP10210', <AP10210/>)
menuComponents.set('AP10220', <AP10220/>)
menuComponents.set('AP10230', <AP10230/>)
menuComponents.set('AP10240', <AP10240/>)
menuComponents.set('AP10300', <AP10300/>)
menuComponents.set('AP10400', <AP10400/>)
menuComponents.set('AP10500', <AP10500/>)