import React, {useContext, useEffect, useState} from "react";
import {DataGrid, GridColDef, GridRowSelectionModel, useGridApiRef} from '@mui/x-data-grid';
import {Box, Button} from "@mui/material";
import {listArea} from "assets/jss/unitStyle";
import {PageContext} from "components/common/pageContext";

interface lisProps {
    data: Array<any>,
    columns: GridColDef[]
    options?: {pageSize:number, checkboxSelection:boolean}
}

const List =({data, columns, options}:lisProps) => {
    const context = useContext(PageContext);

    if(!options){
        options = {
            pageSize : 10,
            checkboxSelection : false
        }
    }

    useEffect(() => {
    }, []);
    return (
        <Box sx={{...listArea, height: '80%'}}>
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: options.pageSize, page: 0 },
                    },
                }}
                checkboxSelection={options.checkboxSelection}

                onRowSelectionModelChange={(newRowSelectionModel) => {
                    context.actions.setRowSelection!(newRowSelectionModel)
                }}
                disableRowSelectionOnClick
                //rowSelectionModel={rowSelectionModel}
                onCellClick={(params)=> context.actions.setViewOpen(true, params.row.id)}
            />
        </Box>
    );
}

export default List;