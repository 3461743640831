import {createContext} from "react";
import {context, initSearchParam} from "../property/contextApiProp";

export const PageContext = createContext<context>({
    viewOpen: false,
    editOpen: false,
    id: '',
    searchParam: initSearchParam,
    listData: {lists:[], count:0},
    actions: {
        setViewOpen: () => {},
        setEditOpen: ()=>{},
        setId: () => {},
        setRefreshKey: () => {},
        //setSearchParam: () => {},
        setListDate: () => {}
    }
});