import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
//import { createLogger  } from 'redux-logger';
import { navItemSlice } from "./slicess/navItemSlice";
import { loginUserSlice } from "./slicess/loginUserSlice";

import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist';
import { persistStore } from 'redux-persist';

//const logger = createLogger();

const rootReducer = combineReducers({
    // 리듀서 정의
    navItem: navItemSlice.reducer,
    //listMember: listMemberSlice.reducer
    loginUser:loginUserSlice.reducer

});


const persistConfig = {
    key: 'root',
    storage,  //로컬스토리지를 사용할 것이기때문에 storage
    whitelist: ['navItem', 'loginUser']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
    enhancers: (defaultEnhancers) => [...defaultEnhancers]
});

export const persist = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;