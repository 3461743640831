import React from "react";
import {Box, Button, Stack} from "@mui/material";
import {listTitleArea} from "../../assets/jss/unitStyle";
import Message from "../unit/message";

export interface ButtonProperty {
    title:string,
    onClick: () => void
}

export interface ButtonProps{
    buttons:Array<ButtonProperty>
}

const ListButton =({buttons}:ButtonProps) => {
    let buttonData
    if(buttons){
        buttonData = buttons.map( (item, key) => {
            return (
                <Button variant="outlined" onClick={item.onClick} key={key}>
                    <Message id={item.title}/>
                </Button>
            )
        })
    }

    // TODO 엑셀 다운로드...
    return (
        <Box sx={listTitleArea}>
            <Stack direction="row" spacing={1}>
                {buttonData}
            </Stack>
        </Box>
    );
}

export default ListButton;