import React, {useState} from "react";
import {PageContext} from "../../components/common/pageContext";
import Page from "../../components/page/page";
import {context, initSearchParam} from "../../components/property/contextApiProp";
import {ButtonProperty} from "../../components/list/listButtom";


function AP10100() {

    const [viewOpen, setViewOpen ] = useState(false)
    const [editOpen] = useState(false)
    const [searchParam] = useState({...initSearchParam})


    const ap10110:context = {
        viewOpen : viewOpen,
        editOpen:editOpen,
        id:'',
        searchParam: searchParam,
        listData:  {lists:[], count:0},
        actions : {
            setViewOpen: setViewOpen,
            //setId: setId,
            setEditOpen: ()=>{

            },
            setListDate:() => {

            }
        }
    }

    const buttons:ButtonProperty[] = [
        {
            title : 'ME00005',
            onClick :  () => {
                alert( '이용중지해제')
            }
        },
        {
            title : 'ME00007',
            onClick :  () => {
                alert( '마스킹해제')
            }
        }
    ]

    return (
        <PageContext.Provider value={ap10110}>
            <Page buttons={buttons} title_description={"Sessions are sessions of users in this realm and the clients that they access within the session."}>
                {/*<ListCustom  columns={columns_10020} resultData={[]}></ListCustom>*/}

            </Page>
        </PageContext.Provider>
    );
}

export default AP10100;