import React, {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Box} from "@mui/material";
import {navItem, setSelectNaveItem} from "modules/slicess/navItemSlice";
import {useAppDispatch, useAppSelector} from "modules/store";
import Message from "components/unit/message";
import {MenusIcon} from "./navBarList";


const NavBarItem:React.FC<navItem> = (item) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const appNavItem  = useAppSelector(state => state.navItem);
    const userInfo  = useAppSelector(state => state.loginUser)

    const handleToggle =  (item:navItem) =>{
        dispatch(setSelectNaveItem(item));
    }

    const goPage = (item:navItem) =>{
        handleToggle(item)
        navigate( item.menuCode ,{ replace: false })
    }

    let treeOpen = item['treeOpen'] ? 'open' : 'close';
    let itemClassName = item.menuCode === appNavItem.selectItem?.menuCode ? 'selected' : '';
    const location = useLocation()

    useEffect(() => {
        const code = item.menuCode.substring(0,5)
        if( location.pathname !== '/'){
            if(appNavItem.selectItem?.menuCode.startsWith(code) && item.children) {
                dispatch(setSelectNaveItem(item));
            }
        }
    }, []);

    const menuBox =()=> {
        return(
            <Box className={`nav-item step-${item.depth} ${itemClassName}`} >
                <Box className="extend">
                    <i className="nav-icon"></i>
                    <Box component="span" sx={{padding: "9px 2px 0 0"}}>{MenusIcon(item.icon)}</Box>
                    <Box className={`nav-text`} onClick={() => goPage(item)}>
                        <Message id={item.menuCode}/>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            {
                item.children && item.children.length > 0 ? (
                        <Box  className={`nav-item step-${item.depth} nav-item-${treeOpen}`}>
                        <Box className="extend" onClick={() => handleToggle(item)}>
                            <Box component="span" sx={{padding: "9px 2px 0 0"}}>{MenusIcon(item.icon)}</Box>
                            <Box component="span" className="nav-text" >
                               <Message id={item.menuCode}/>
                            </Box>
                        </Box>
                        <Box className="sub-container">
                            {item.children.map((childrenItem, index) => {
                                return (
                                    <NavBarItem {...childrenItem}  key={childrenItem.menuCode}/>
                                )
                            })}
                        </Box>
                    </Box>
                ) : (
                    // user 메뉴는 just 노출
                    item.manager === 'user' ? menuBox() : (userInfo.loginInfo.manage ? menuBox() : '')
                )
            }
        </>
    )
}
export default NavBarItem
