import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface navItem {
    menuCode:string,
    manager?:string,
    treeOpen:boolean,
    router?:boolean
    depth:Number,
    children?: navItem[],
    icon?: any
}

export interface navItemState {
    navItemList : navItem[],
    selectItem? : navItem
}

const initialState: navItemState = {
    navItemList: []
}

const setSelectRecursiveNaveItem = (state:navItemState, item:navItem, selectNavItem:navItem) => {
    if(item.menuCode === selectNavItem.menuCode) {
        item.treeOpen = !item.treeOpen
        if(item.router){
            state.selectItem = selectNavItem; // 라우터가 있는 경우 현재 선택한 메뉴로 셋팅
        }
        return true;
    }

    if(item.children) {
        item.children.some( item=> {
            setSelectRecursiveNaveItem(state, item, selectNavItem)
        })
    }
    return false;
}

export const navItemSlice = createSlice({
    name: 'navItem',
    initialState,
    reducers: {
        setNavItem(state, action: PayloadAction<navItem[]>) {
            state.navItemList = action.payload
        },
        setSelectNaveItem(state, action: PayloadAction<navItem>) {
            const selectNavItem = action.payload
            state.navItemList.some(item => { // some 이용한 순회를 한 이유는 조건에 맞으면 break 하기 위해서
                return setSelectRecursiveNaveItem(state, item, selectNavItem)
            })
        }
    }
})

export const {setNavItem, setSelectNaveItem } = navItemSlice.actions

//export default navItemSlice.reducer;