import React from "react";
import Page from "../../components/page/page";


function AP10240() {

    return (
        <Page>
        </Page>
    );
}

export default AP10240;