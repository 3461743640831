import {searchBoardParam, searchParam} from "../page/search";
import {listData} from "./listColumnProp";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import {clientProp} from "views/client/clientView";
import {userProp} from "views/user/userView";

export const initSearchParam:searchParam = {
    executeEvent: 'SEARCH',
    filter : {
        pageIndex: 0,
        pageSize: 10,
        value: ''
    }
}

export interface context  {
    viewOpen:boolean,
    editOpen:boolean,
    id:string,
    refreshKey?:string,
    targetProp?: clientProp|userProp
    searchParam: searchParam|searchBoardParam,
    listData?: listData,
    alertDialog?: {open:boolean, title:string, content:string, handleOK?:()=>void, handleCancel?:() => void},
    rowSelection?: GridRowSelectionModel[]
    actions: {
        setViewOpen:(open:boolean, id?:string) => void,
        setEditOpen?:(editOpen:boolean, id?:string) => void,
        setId?:(id:number)=>void,
        setRefreshKey?:(refreshKey:string)=>void,
        //setSearchParam?:(searchParam:searchParam) => void,
        setListDate: (searchParam?: searchParam | searchBoardParam, targetProp?: {}) => void,
        setAlertDialog?:(content:string, title?:string, callback?:()=>void) => void
        setRowSelection?:(rowSelectionModel: GridRowSelectionModel)=>void
    }
}