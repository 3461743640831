import React from "react";
import Page from "../../components/page/page";
import { ResponsiveBump } from '@nivo/bump'
import {listArea} from "../../assets/jss/unitStyle";
import {Box} from "@mui/material";


function AP10300() {

    const data = [
        {
            "id": "휴레이",
            "data": [
                {
                    "x": 2000,
                    "y": 11
                },
                {
                    "x": 2001,
                    "y": 12
                },
                {
                    "x": 2002,
                    "y": 2
                },
                {
                    "x": 2003,
                    "y": 11
                },
                {
                    "x": 2004,
                    "y": 5
                }
            ]
        },
        {
            "id": "케어닥",
            "data": [
                {
                    "x": 2000,
                    "y": 1
                },
                {
                    "x": 2001,
                    "y": 10
                },
                {
                    "x": 2002,
                    "y": 1
                },
                {
                    "x": 2003,
                    "y": 7
                },
                {
                    "x": 2004,
                    "y": 9
                }
            ]
        },
        {
            "id": "gccare",
            "data": [
                {
                    "x": 2000,
                    "y": 4
                },
                {
                    "x": 2001,
                    "y": 5
                },
                {
                    "x": 2002,
                    "y": 8
                },
                {
                    "x": 2003,
                    "y": 6
                },
                {
                    "x": 2004,
                    "y": 7
                }
            ]
        },
        {
            "id": "위드비컨",
            "data": [
                {
                    "x": 2000,
                    "y": 9
                },
                {
                    "x": 2001,
                    "y": 4
                },
                {
                    "x": 2002,
                    "y": 5
                },
                {
                    "x": 2003,
                    "y": 8
                },
                {
                    "x": 2004,
                    "y": 4
                }
            ]
        },
        {
            "id": "스포애니",
            "data": [
                {
                    "x": 2000,
                    "y": 5
                },
                {
                    "x": 2001,
                    "y": 8
                },
                {
                    "x": 2002,
                    "y": 10
                },
                {
                    "x": 2003,
                    "y": 2
                },
                {
                    "x": 2004,
                    "y": 11
                }
            ]
        },
        {
            "id": "인터파크",
            "data": [
                {
                    "x": 2000,
                    "y": 2
                },
                {
                    "x": 2001,
                    "y": 2
                },
                {
                    "x": 2002,
                    "y": 4
                },
                {
                    "x": 2003,
                    "y": 3
                },
                {
                    "x": 2004,
                    "y": 2
                }
            ]
        },
        {
            "id": "kb은행",
            "data": [
                {
                    "x": 2000,
                    "y": 10
                },
                {
                    "x": 2001,
                    "y": 6
                },
                {
                    "x": 2002,
                    "y": 9
                },
                {
                    "x": 2003,
                    "y": 5
                },
                {
                    "x": 2004,
                    "y": 6
                }
            ]
        },
        {
            "id": "cps",
            "data": [
                {
                    "x": 2000,
                    "y": 12
                },
                {
                    "x": 2001,
                    "y": 7
                },
                {
                    "x": 2002,
                    "y": 7
                },
                {
                    "x": 2003,
                    "y": 9
                },
                {
                    "x": 2004,
                    "y": 3
                }
            ]
        },
        {
            "id": "홈핏",
            "data": [
                {
                    "x": 2000,
                    "y": 8
                },
                {
                    "x": 2001,
                    "y": 3
                },
                {
                    "x": 2002,
                    "y": 6
                },
                {
                    "x": 2003,
                    "y": 4
                },
                {
                    "x": 2004,
                    "y": 1
                }
            ]
        }
    ]


    return (
        <Page title_description={"APIs call by client and traffic"}>
            <Box sx={{...listArea, height: '80%'}}>
            <ResponsiveBump
                data={data}
                colors={{ scheme: 'spectral' }}
                lineWidth={3}
                activeLineWidth={6}
                inactiveLineWidth={3}
                inactiveOpacity={0.15}
                pointSize={10}
                activePointSize={16}
                inactivePointSize={0}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={3}
                activePointBorderWidth={3}
                pointBorderColor={{ from: 'serie.color' }}
                axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -36
                }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'ranking',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
                axisRight={null}
            />
            </Box>
        </Page>
    );
}

export default AP10300;