

/// Page layOUt Start
import {makeStyles} from "@mui/styles";

export const pageTitleArea = {
    //position: 'absolute',
    display:"flex",
    flexDirection: 'row',
    width: '100%',
    m:1
};

export const searchArea = {
    ...pageTitleArea,
    width: '99%'
};

export const searchDetailArea = {
    ...pageTitleArea,
    width: '99%'
};

export const listTitleArea = {
    ...pageTitleArea,
    width: '99%'
    //top:135,
};

export const listArea = {
    ...pageTitleArea,
     width: '99%',
    // minWidth: '200px'
    //minHeight: '500px'
    //height: 500,
    //top:180
};

export const listPagination = {
    //...pageTitleArea,
    width: '99%',
    position: 'relative',
    textAlign: 'center'
};
/// Page layOUt End


export const dividerStyle = {width: '100%', borderColor: '#fac84b'}
export const grayStyle = {width: '100%', borderColor: '#D8D8D8'}

export const infoStyles = {display:"block", whiteSpace:"nowrap",overflow:"hidden", textOverflow: "ellipsis", paddingLeft:1, fontSize: "15px"}
export const headStyles = {...infoStyles, fontWeight: "bold" ,background:"#F2F2F2"}

export const scopeDrawerStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: 1000,
        height: 500,
        top:222,
        border:2,
        borderColor: "#fac84b",
        borderStyle: "solid",
    }
}));


export const clientStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: 1000,
        height: 600,
        top:300,
        border:1,
        marginRight:30,
        borderColor: "#fac84b",
        borderStyle: "solid",
        //backgroundColor: '#fafafa'
    }
}));

export const drawerStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: 1000,
        height: 600,
        top:300,
        border:1,
        marginRight:30,
        borderColor: "#fac84b",
        borderStyle: "solid",
    }
}));
