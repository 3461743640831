import React, {useEffect, useState} from "react";
import Page from "../../components/page/page";
import {Card, CardContent, Grid, IconButton, Typography} from "@mui/material";
import {getPublicKey} from "../../services/apiService";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import {CopyToClipboard} from "react-copy-to-clipboard";


function AP10220() {

    const [publicKey, setPublicKey] = useState( "");

    useEffect(() => {

        getPublicKey().then(response => {
            setPublicKey(response.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page title_description={"public and private key manage"}>
            <Card variant="outlined" sx={{background:'#FAFAFA', height:'2100px'}}>
                <CardContent>
                    <Grid container  alignItems="center" justifyContent="left" sx={{mt:1}}>
                        <Typography  gutterBottom variant="h6">
                            ▶︎ 공개키 확인
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="left">
                        <Grid item>
                            <Typography
                                fontSize="14px"
                                gutterBottom
                            >
                                {publicKey.substring(0, 100)}...
                                <CopyToClipboard text={publicKey}>
                                    <IconButton>
                                        <ContentCopyTwoToneIcon fontSize="small"/>
                                    </IconButton>
                                </CopyToClipboard>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Page>
    );
}

export default AP10220;