import React from 'react'
import Title from "./title";
import Search from "./search";
import ListButton, {ButtonProperty} from "../list/listButtom";

interface PageProps {
    children: React.ReactNode
    buttons? : Array<ButtonProperty>
    title_description?:string
}

const Page = ({children, buttons, title_description}:PageProps) => {
    return (
        <>
            <Title description={title_description}/>
            {/*<Search/>*/}
            {buttons ? <ListButton buttons={buttons}/> : ''}
            {children}
        </>
    )
}
export default Page
