import React, {FC, useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Divider, Grid, TextField, Typography} from "@mui/material";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import Cookies from "universal-cookie";
import SendIcon from '@mui/icons-material/Send';
import {useFormik} from "formik";
import {useAppDispatch, useAppSelector} from "modules/store";
import {fetchUserInfo} from "../../modules/slicess/loginUserSlice";
import AlertDialog, {DialogProps} from "components/unit/alertDialog";
import {loginAction} from "services/apiService";
import {useTheme} from "@mui/material/styles";
import Google from 'assets/imgs/ico/social-google.svg';
import Naver from 'assets/imgs/ico/social-naver.png';
import Kakao from 'assets/imgs/ico/social-kakao.png';
//import ocare from 'assets/imgs/ico/ocare.png';

const  cookie = new Cookies();

interface oauth{
    provider:string,
    callUrl:string,
    callBack:string|undefined,
    clientId:string
}

const g_oauth:oauth = {
    provider : 'google',
    callUrl : "https://accounts.google.com/o/oauth2/v2/auth",
    callBack : process.env.REACT_APP_GOOGLE_REDIRECT_URL,
    clientId : "531232389083-1ls0ivohkg5480sksbdrgv495i1gcekt.apps.googleusercontent.com"
}


const n_oauth:oauth = {
    provider : 'naver',
    callUrl : "https://nid.naver.com/oauth2.0/authorize",
    callBack : process.env.REACT_APP_NAVER_REDIRECT_URL,
    clientId : "4UhuUNpJ59FlwyrfMb0U",
}


const k_oauth:oauth = {
    provider : 'kakao',
    callUrl : "https://kauth.kakao.com/oauth/authorize",
    callBack : process.env.REACT_APP_KAKAO_REDIRECT_URL,
    clientId : "51e9ad19164faba71f91bf7f98b80860",
}

interface Oauth2Token{
    access_token:string,
    token_type:string,
    refresh_token:string,
    expires_in:number,
    refresh_expires_in:number
}


axios.defaults.withCredentials = true;


const LoginPage:FC = () => {
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userInfo  = useAppSelector(state => state.loginUser)

    const [props, setProps] = useState({
        dialogOpen:false,
        title: 'ME00053',
        content:'',
        codeOpen:false
    })

    const tokenSet = (access_token?:string|null, refresh_token?:string|null ) => {
        cookie.set('access_token', access_token)
        cookie.set('refresh_token', refresh_token)
    }
   const alertProps:DialogProps = {
        open:props.dialogOpen,
        handleOK: () => {
            setProps( {
                ...props, dialogOpen: false
            })
        },
        title:props.title,
        content:props.content,
    }

    useEffect(() => {
            if (searchParams.get('access_token') && searchParams.get('refresh_token')) {
                tokenSet(searchParams.get('access_token'), searchParams.get('refresh_token'))
                dispatch(fetchUserInfo({'type': 'token', 'targetID': searchParams.get('access_token') + ''}))
                navigate("/", {replace: true})
            }
        }, [userInfo.loginInfo.email])


    const oauthLogin =(oauth:oauth)=>{
        window.location.href  = oauth.callUrl + '?response_type=code&client_id=' + oauth.clientId + '&redirect_uri=' + oauth.callBack + (oauth.provider === 'google' ? '&scope=openid profile email' : '')
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            code: ''
        },
        onSubmit: (values) => {
            if(!values.email){
                setProps( {...props, dialogOpen: true, content: 'ME10000'})
                return ;
            }

            if(props.codeOpen && !values.code){
                setProps( {...props, dialogOpen: true, content: 'ME10001'})
                return;
            }

            setProps( {...props, codeOpen: true})

            if(values.email && values.code){
                /// 서브 밋 !!
                loginAction(values).then(response => {
                    if(response === 'Unauthorized'){
                        setProps( {...props, dialogOpen: true, content: 'ME10021'})
                        return
                    }

                    if(response){
                        const token = response as Oauth2Token
                        tokenSet(token.access_token, token.refresh_token)
                        dispatch(fetchUserInfo( {'type':'token', 'targetID': token.access_token}))
                        navigate( "/",{ replace: true })
                    }
                    setProps( {...props, dialogOpen: true, content: 'ME10006'})

                })
            }
        }
    })

    return (
        <Grid container justifyContent="center" alignItems="center" height="100vh" >
            <Card variant="outlined" sx={{background:'#FAFAFA'}}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        {/*<Grid item alignItems="center" justifyContent="center">*/}
                        {/*    <img src={ocare} alt="ocare" width={40} height={30} style={{ marginRight: 17}} />*/}
                        {/*</Grid>*/}

                        <Grid item alignItems="center" justifyContent="center">
                            <Typography
                                color={theme.palette.secondary.main}
                                gutterBottom
                            >
                                Hi, Welcome Back
                            </Typography>
                            <Typography
                                variant="caption"
                                fontSize="16px"
                                textAlign="center"
                            >
                                Enter your credentials to continue
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography sx={{ mt: 4 }} component="div" variant="body2" color="text.secondary" align="center">
                        <form>

                            <TextField name="email" size="medium" autoFocus label="이메일을 입력해주세요" onChange={formik.handleChange} value={formik.values.email} variant="outlined" fullWidth/>
                            {/*<OutlinedInput  name="email" type="email"  label="email" onChange={formik.handleChange} value={formik.values.email} inputProps={{}}></OutlinedInput>*/}

                            <Button sx={{ mt: 1 }} size="medium" fullWidth variant="contained" onClick={()=>formik.handleSubmit()} endIcon={<SendIcon />}>
                                Sing In
                            </Button>

                            <Typography component="div" sx={props.codeOpen ?  {mt: 2, display:''} : {mt: 1, display:'none'} } color="text.secondary">
                                <TextField  name="code" size="medium" label="로그인 코드를 붙여주세요"
                                            variant="outlined"
                                            fullWidth autoFocus
                                            onChange={formik.handleChange}
                                            value={formik.values.code}
                                            color="warning"
                                            helperText="방금 메일 주소로 로그인 코드를 발급하였습니다."/>
                            </Typography>
                        </form>

                        {/*<Button sx={{ mt: 1 }} size="small" color="error" fullWidth variant="contained" onClick={()=> authSite(g_oauthLogin())} endIcon={<SendIcon />}>*/}
                        {/*    <Message id={'ME10004'}/>*/}
                        {/*</Button>*/}

                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex'
                                }}
                            >
                                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                                <Button
                                    variant="outlined"
                                    sx={{
                                        cursor: 'unset',
                                        m: 2,
                                        py: 0.5,
                                        px: 7,
                                        borderColor: `${theme.palette.grey[100]}`,
                                        color: `${theme.palette.grey[900]}`,
                                        fontWeight: 500,
                                        borderRadius: `14px`
                                    }}
                                    disableRipple
                                    disabled
                                >
                                    OR
                                </Button>

                                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            {/*<Button*/}
                            {/*    onClick={()=> oauthLogin(g_oauth)}*/}
                            {/*    size="medium"*/}
                            {/*    variant="outlined"*/}
                            {/*    sx={{*/}
                            {/*        //color: 'grey.700',*/}
                            {/*        //backgroundColor: theme.palette.grey[50],*/}
                            {/*        borderColor: '#FAFAFA'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Box paddingTop={"9px"}>*/}
                            {/*        <img src={Google} alt="google" width={45} height={45}/>*/}
                            {/*    </Box>*/}
                            {/*</Button>*/}
                            <Button
                                onClick={()=> oauthLogin(n_oauth)}
                                size="medium"
                                variant="outlined"
                                sx={{
                                    //color: 'grey.700',
                                    //backgroundColor: theme.palette.grey[50],
                                    borderColor: '#FAFAFA'
                                }}
                            >
                                <Box paddingTop={"9px"}>
                                    <img src={Naver} alt="naver" width={45} height={45} />
                                </Box>
                            </Button>

                            <Button
                                onClick={()=> oauthLogin(k_oauth)}
                                size="medium"
                                variant="outlined"
                                sx={{
                                    //color: 'grey.700',
                                    //backgroundColor: theme.palette.grey[50],
                                    borderColor: '#FAFAFA'
                                }}
                            >
                                <Box paddingTop={"9px"}>
                                    <img src={Kakao} alt="kakao" width={45} height={45} />
                                </Box>
                            </Button>
                        </Grid>

                    </Typography>

                    <Typography sx={{ fontSize: 14 , mt:3}} color="text.secondary" align="center">
                        이용약관 개인정보처리방침
                    </Typography>
                </CardContent>
            </Card>

            <AlertDialog open={alertProps.open}
                // handleClickOpen={alertProps.handleClickOpen}
                         handleOK={alertProps.handleOK}
                         title={alertProps.title}
                         content={alertProps.content}
            />
        </Grid>
    );
}
export default LoginPage