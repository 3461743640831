import React from 'react'
import {Box, Container, Divider, Typography} from "@mui/material";
import {useAppSelector} from "modules/store";
import {dividerStyle, pageTitleArea} from "assets/jss/unitStyle";
import Message from "../unit/message";
import {ButtonProperty} from "../list/listButtom";

interface TitleProps {
    description?:string
}

const Title = ({description}:TitleProps) => {
    const selectItem  = useAppSelector(state => state.navItem.selectItem)
    return (
        <>
            <Box sx={{pageTitleArea}}>
                <Typography variant="h4" gutterBottom component="div" sx={{ml:3}}>
                    {selectItem ? <Message id={selectItem.menuCode}/> : ''}
                </Typography>
                <Typography  component="div" sx={{ml:3, mb:2}}>
                    {description}
                </Typography>
            </Box>
            <Divider sx={dividerStyle}/>
        </>
    )
}
export default Title