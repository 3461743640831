import {Box, IconButton, Tooltip} from "@mui/material";

import logo from 'assets/imgs/logo/logo.png'

import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
//import Person2TwoToneIcon from '@mui/icons-material/Person2TwoTone';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import React from "react";
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../modules/store";
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import {fetchUserInfo} from "../../../modules/slicess/loginUserSlice";


const TopBar = () => {

    const  cookie = new Cookies();
    const navigate = useNavigate()
    const login = useAppSelector(state => state.loginUser);
    const dispatch = useAppDispatch()

    const loginOut = async () => {
        cookie.remove( 'access_token', { path: '/' })
        cookie.remove( 'refresh_token', { path: '/' })
        dispatch(fetchUserInfo({'type': 'logout', 'targetID': ''}))

        navigate( "/login",{ replace: true })
    }

    const question = async () => {

    }
    const notice =()=>{
    }
    const getUserInfo = async () => {
        //console.log( login)
    }

    console.log(login.loginInfo.roles)


    return (
        <>
            <Box id="headerWrap">
                <Box id="headFnc">
                    <Box id="main-ci">
                        <Box id="logo"><img src={logo} alt="KB헬스케어"/></Box>
                    </Box>
                    <Box className="headfnc">
                        <Box className="head-misc">
                            <Box className="user-info" >
                                <Box className="divi_txt" style={{background: 'none'}}>
                                    {/*{IcButton( 'sessionInfo', <AutorenewRoundedIcon  sx={{ fontSize: 32 }} color="action"/>)}*/}


                                    <IconButton onClick={question}>
                                        <QuizTwoToneIcon fontSize="medium"/>
                                    </IconButton>

                                    <Tooltip title={login.loginInfo.name}>
                                        <IconButton onClick={getUserInfo}>
                                            <AccountBoxIcon fontSize="large"/>
                                        </IconButton>
                                    </Tooltip>

                                    {/*<Button variant="outlined" startIcon={<SearchIcon />} sx={{height:55}} onClick={search}>*/}
                                    {/*    <Message id="ME00001"></Message>*/}
                                    {/*</Button>*/}

                                    <IconButton onClick={notice}>
                                        <NotificationsTwoToneIcon fontSize="medium"/>
                                    </IconButton>

                                    <Tooltip title="logout">
                                        <IconButton onClick={loginOut}>
                                            <LogoutTwoToneIcon fontSize="medium"/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="headerTitle">
                        <Box className="menuLnb"></Box>
                        <Box className="tit">Oauth2.0 System</Box>
                    </Box>
                </Box>
                {/*<HeaderTab registerRefHeaderTab={this.props.registerRefHeaderTab}/>*/}
            </Box>
        </>
    )
}

export default TopBar