/**
 *  Level 1 : 10000,  100100, 100200..
 *  Level 2 : 10010,  100110, 100210..
 *  Level 3 : 10011,  100111, 100211..
 */
import {navItem} from "modules/slicess/navItemSlice";
import ManageSearchTwoToneIcon from '@mui/icons-material/ManageSearchTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import React from "react";


export const MenusIcon =(title:string)=>{
    switch (title){
        case 'ManageSearchTwoToneIcon':
            return <ManageSearchTwoToneIcon fontSize={"small"}/>
        case 'BarChartTwoToneIcon':
            return <BarChartTwoToneIcon fontSize={"small"}/>
        case 'ManageAccountsTwoToneIcon':
            return <ManageAccountsTwoToneIcon fontSize={"small"}/>
        case 'ForumTwoToneIcon':
            return <ForumTwoToneIcon fontSize={"small"}/>
        case 'ArticleTwoToneIcon':
            return <ArticleTwoToneIcon fontSize={"small"}/>
        default:
            return ''
    }
}

// ManageSearchTwoToneIcon
export const defaultMenus: navItem[] =    [
    { menuCode: 'AP10000', manager:'user', icon: 'ManageSearchTwoToneIcon',  depth:1, treeOpen:false, children: [
            { menuCode: 'AP10010', manager:'user', router:true,  icon: '', treeOpen:false,depth:2},
            { menuCode: 'AP10020', manager:'admin', router:true, icon: '', treeOpen:false,depth:2},
            { menuCode: 'AP10030', manager:'admin', router:true, icon: '', treeOpen:false,depth:2},
            //{ menuCode: 'AP10040', router:true, icon: '', treeOpen:false,depth:2}
        ]
    },
    // { menuCode: 'AP10100', icon: '', depth:1, treeOpen:false },
    // { menuCode: 'AP10100', router:true, icon:  'BarChartTwoToneIcon', depth:1, treeOpen:false },
    { menuCode: 'AP10200',  manager:'user', icon: 'ManageAccountsTwoToneIcon', depth:1 , treeOpen:false, children: [
            { menuCode: 'AP10210',  manager:'user', router:true, icon: '', treeOpen:false,depth:2},
            { menuCode: 'AP10220',  manager:'admin', router:true, icon: '', treeOpen:false,depth:2},
            { menuCode: 'AP10230',  manager:'admin', router:true, icon: '', treeOpen:false,depth:2},
            // { menuCode: 'AP10240', router:true, icon: '', treeOpen:false,depth:2}
        ]
    },
    { menuCode: 'AP10300',  manager:'admin', router:true, icon: 'BarChartTwoToneIcon', depth:1, treeOpen:false },
    { menuCode: 'AP10400',  manager:'user',  router:true, icon: 'ArticleTwoToneIcon', depth:1, treeOpen:false },
    { menuCode: 'AP10500',  manager:'user',  router:true, icon: 'ForumTwoToneIcon', depth:1, treeOpen:false }
]