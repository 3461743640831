import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from "@mui/material";
import {clientStyles, dividerStyle, grayStyle, headStyles} from "assets/jss/unitStyle";
import Message from "components/unit/message";
import {PageContext} from "components/common/pageContext";
import {ButtonProperty} from "components/list/listButtom";
import {GridHead, GridInfo} from "components/unit/grid";
import TabPanel from "components/unit/tabPanel";
import ScopeListDialog from "views/scope/scopeListDialog";
import {TableBodyCell, TableHeadCell} from "components/unit/table";
import {deleteClient, deleteScopeMapping, updateSecret} from "services/apiService";
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import {searchParam} from "../../components/page/search";

export interface clientProp {
    id: string,
    secret: string,
    name: string,
    authorizedGrantTypes: string,
    redirectUri: string,
    accessTokenValidity: string,
    refreshTokenValidity: string,
    allowedIps: string,
    createDate: string,
    scopes:scopeProp[]
}

export interface scopeProp {
    id: number,
    name: string,
    description: string
}

const ClientView = () => {
    const classes = clientStyles()
    const context = useContext(PageContext)
    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const buttons:ButtonProperty[] = [
        {
            title : 'ME00005',
            onClick :  () => {
                // 수정
                //const client = context.targetProp as clientProp
                context.actions.setEditOpen!(true, context.id)
            }
        },
        {
            title : 'ME00013', // 삭제
            onClick :  () => {
                context.actions.setAlertDialog!('ME10017', '', handelDeleteClient)
            }
        },
        {
            title : 'ME00055',
            onClick :  () => {
                context.actions.setViewOpen(false)
            }
        }
    ]

    const updateSecret_ =() => {
        updateSecret( {id : context.id}).then(response => {
            console.log(response)
            context.actions.setListDate(context.searchParam as searchParam, response as clientProp)
        })
    }

    const handelUpdateSecret =()=>{
        context.actions.setAlertDialog!('ME10018', '', updateSecret_)
    }

    const handelDeleteClient =() => {
        deleteClient(context.id).then(response => {
            context.actions.setListDate()
        })
    }

    const handelDeleteScopeMapping =(scopeId:number) => {
        deleteScopeMapping({'clientId': context.id , 'scopeId' : scopeId}).then(response => {
            //context.actions.setRefreshKey!(utils.generateKey()) // 속성내용 새로고침

            context.actions.setViewOpen(true, context.id)
        })
    }

    // 마우스 영역에서 Out 된 경우
    // function handleClickOutside(e: MouseEvent): void {
    //     if (drawerRef.current &&
    //         !drawerRef.current.contains(e.target as Node)){
    //         context.actions.setOpen(false)
    //     }
    // }
    // document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    // };

    const client = context.targetProp as clientProp
    useEffect(() => {
        // if(context.viewOpen){
        //     console.log("> client View")
        // }

        //context.targetObject
        // 새로 고침을 위해 사용!.
         //context.actions.setRefreshKey = setRefreshKey;

    }, [context.id, context.viewOpen]);


    const allProps =(index: number)=>{
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    return (
        <>
                <React.Fragment key='right'>
                    <Drawer
                        open={context.viewOpen}
                        variant="persistent"
                        anchor="right"
                        classes={{paper: classes.drawerPaper}}
                        //ref={drawerRef}
                    >
                        <Box sx={{m:1}}>
                            <Typography variant="h6" component="span" >
                                <IconButton onClick={()=>context.actions.setViewOpen(false)}>
                                    <ArrowCircleRightTwoToneIcon fontSize="medium" />
                                    Client Detail
                                </IconButton>
                            </Typography>
                        </Box>
                        <Divider sx={dividerStyle}/>


                        <Tabs
                            value={tabIndex}
                            onChange={handleChange}
                            //indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{mt:1}}
                        >
                            <Tab label="Settings"  {...allProps(0)}/>
                            <Tab label="Scopes" {...allProps(1)} />
                        </Tabs>


                        <TabPanel value={tabIndex} index={0}>
                            <Box sx={{m:0, lineHeight:3}}>
                                <Grid container>
                                    <Grid item sx={{borderTopStyle:'solid', borderTopColor:'#fac84b'}}  xs={12}></Grid>
                                </Grid>
                                <Grid container>
                                    <GridHead text={"ME00060"} xs={2}/>
                                    <GridInfo text={client.id} xs={4}/>
                                    <GridHead text={"ME00066"} xs={2}/>
                                    <GridInfo text={client.createDate} xs={4}/>
                                </Grid>
                                <Divider sx={grayStyle}/>
                                <Grid container>
                                    <GridHead text={"ME00062"} xs={2}/>
                                    <GridInfo text={client.name} xs={10}/>
                                </Grid>
                                <Divider sx={grayStyle}/>
                                <Grid container>
                                    <GridHead text={"ME00067"} xs={2}/>
                                    <GridInfo xs={10}>
                                        <Box component={"span"} sx={{mr:1}}>{client.secret}</Box>
                                        <IconButton onClick={()=> handelUpdateSecret()}>
                                            <CreateTwoToneIcon fontSize="medium"/>
                                        </IconButton>
                                    </GridInfo>
                                </Grid>
                                <Divider sx={grayStyle}/>
                                <Grid container>
                                    <GridHead text={"ME00068"} xs={2}/>
                                    <GridInfo text={client.authorizedGrantTypes} xs={10}/>
                                </Grid>
                                <Divider sx={grayStyle}/>
                                <Grid container>
                                    <GridHead text={"ME00069"} xs={2}/>
                                    <GridInfo text={client.redirectUri} xs={10}/>
                                </Grid>
                                <Divider sx={grayStyle}/>
                                <Grid container>
                                    <GridHead text={"ME00070"} xs={2}/>
                                    <GridInfo text={client.accessTokenValidity} xs={4}/>
                                    <Grid  item xs={2}  sx={headStyles}>
                                        <Tooltip title="Refresh Token Life" placement="bottom-end">
                                            <Box><Message id={"ME00072"}/></Box>
                                        </Tooltip>
                                    </Grid>
                                    <GridInfo text={client.refreshTokenValidity} xs={4}/>
                                </Grid>
                                <Divider sx={grayStyle}/>
                                <Grid container>
                                    <GridHead text={"ME00073"} xs={2}/>
                                    <GridInfo text={client.allowedIps} xs={10}/>
                                </Grid>
                                <Divider sx={grayStyle}/>
                            </Box>

                            <Grid container justifyContent="center">
                                <ButtonGroup sx={{m: 2}} size="large" variant="outlined" aria-label="button group">
                                    {
                                        buttons.map((item, key) => {
                                            return (
                                                <Button onClick={item.onClick} key={key}>
                                                    <Message id={item.title}/>
                                                </Button>
                                            )
                                        })
                                    }
                                </ButtonGroup>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={tabIndex} index={1}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{background:'#f7f7f7', borderTopStyle:'solid', borderTopColor:'#fac84b'}}>
                                        <TableHeadCell text={'API'} width={"200"}/>
                                        <TableHeadCell text={'Description'} width={"400"}/>
                                        <TableHeadCell align={'center'} text={'비고'} width={"50"}/>
                                    </TableRow>
                                </TableHead>
                                { client.scopes && client.scopes.length > 0 ? (
                                    <TableBody>
                                        {
                                            client.scopes.map( (scope, index) => {
                                                return (
                                                    <TableRow  key={ 'body_row_'+ index}>
                                                        <TableBodyCell text={scope.name}/>
                                                        <TableBodyCell text={scope.description}/>
                                                        <TableCell   align="center" variant="body"
                                                                     padding="none" 
                                                        >
                                                            <IconButton onClick={()=> handelDeleteScopeMapping(scope.id)}>
                                                                <DeleteForeverTwoToneIcon fontSize="small"/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                ) :  (
                                    <TableBody>
                                        <TableRow>
                                            <TableBodyCell text={'추가 버튼을 통해서 Scope 등록하세요.'}/>
                                            <TableBodyCell text={''}/>
                                        </TableRow>
                                    </TableBody>
                                )
                                }
                            </Table>
                            <Grid container justifyContent="center" alignItems="center" mt={5}>
                                <ScopeListDialog/>
                            </Grid>
                        </TabPanel>

                    </Drawer>
                </React.Fragment>
        </>
    )
}

export default ClientView