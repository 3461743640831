import React, {useEffect, useState} from "react";

import List from "components/list/list";
import Page from "components/page/page";
import {ButtonProperty} from "components/list/listButtom";
import {context, initSearchParam} from "components/property/contextApiProp";
import {deleteScope, searchScope} from "services/apiService";
import {scopeColumns} from "components/list/listHead";
import {PageContext} from "components/common/pageContext";
import ScopeSave from "views/scope/scopeSave";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import AlertDialog from "../../components/unit/alertDialog";


function AP10020() {

    const [props, setProps] = useState({
        editOpen: false,
        id:'',
        searchParam: {...initSearchParam},
        rowSelection: new Array<GridRowSelectionModel>(),
        listData : {lists:[], count:0},
        alertDialog : {open:false, title:'ME00053', content:'ME10016'}
    })

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    const handleOK =()=> {
        deleteScope({'filter': { 'ids' : rowSelectionModel }}).then(response => {
            ap10020.actions.setListDate()
        })
    }

    const handleCancel =()=> {
        setProps({...props, alertDialog : {...props.alertDialog, open:false}});
    }


    const ap10020:context = {
        viewOpen : false,
        editOpen: props.editOpen,
        id: props.id,
        searchParam: props.searchParam,
        listData: props.listData,
        //rowSelection:rowSelectionModel,
        actions : {
            setViewOpen: ()=> {},
            //setId : setId,
            setEditOpen: () => {
                setProps( {...props , editOpen: !props.editOpen})
            },
            setListDate:() => {
                searchListData()
            },
            setRowSelection:(rowSelectionModel)=>{
                 setRowSelectionModel(rowSelectionModel)
            }
        }
    }

    const searchListData = () => {
        searchScope(props.searchParam).then(response => {
            if(response){
                setProps( {...props, editOpen : false
                    , alertDialog : {...props.alertDialog, open:false}
                    , listData: {lists:response as [], count: 0}})
            }

        })
    }

    useEffect(() => {
        searchListData()
    }, []);

    // 필요한 버튼 설정
    const buttons:ButtonProperty[] = [
        {
            // 등록
            title : 'ME00014',
            onClick :  () => {
                ap10020.actions.setEditOpen!(!props.editOpen)
            }
        },
        {
            // 삭제
            title : 'ME00013',
            onClick :  () => {
                setProps({...props, alertDialog : {...props.alertDialog, open:true}});
            }
        },
        {
            // 새로고침
            title : 'ME00019',
            onClick :  () => {
                searchListData()
            }
        }
    ]

    return (
        <>
            <PageContext.Provider value={ap10020}>
                <Page buttons={buttons} title_description={"Client scopes are a common set of protocol mappers and roles that are shared between multiple clients."}>
                    <List data={props.listData.lists} columns={scopeColumns} options={{pageSize:50, checkboxSelection: true}}/>
                    <ScopeSave/>
                </Page>

                <AlertDialog open={props.alertDialog.open}
                             handleOK={handleOK}
                             handleCancel={handleCancel}
                             title={props.alertDialog.title}
                             content={props.alertDialog.content}
                />
            </PageContext.Provider>
        </>
    );
}

export default AP10020;