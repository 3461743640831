import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import {persist, store} from "modules/store";
import { BrowserRouter } from "react-router-dom";
import enMsg from "assets/lang/ko.json";
import koMsg from "assets/lang/ko.json"
import {IntlProvider} from "react-intl";
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const messages = { "en": enMsg, ko: koMsg}["ko"];
// 리덕스 설정
root.render(
  // <React.StrictMode>
        <BrowserRouter>
          <Provider store={store}>
              <PersistGate persistor={persist}>
              <IntlProvider locale={"ko"} messages={messages}>
                    <App />
              </IntlProvider>
              </PersistGate>
          </Provider>
        </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to logo results (for example: reportWebVitals(console.logo))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();