import * as React from 'react';
import {TableCell} from "@mui/material";


interface tableProp {
    text:string|number,
    width?:string,
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}


export function TableHeadCell({text, width, align}:tableProp) {

    return (
        <TableCell  align={align ? align : 'left'} variant="head"
                    sx={{fontSize: "15px", fontWeight: "bold", height:"20px"}}
                    padding="normal"  size="medium"
                    width={width}>
            {text}
        </TableCell>
    );
}

export function TableBodyCell({text, width, align}:tableProp) {
    return (
        <TableCell    align={align ? align : 'left'} variant="body"
                     sx={{fontSize: "14px"}}
                     padding="normal" size="medium"
                     width={width}>
            {text}
        </TableCell>
    );
}