import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {crateScopeMapping, searchScope} from "../../services/apiService";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Message from "components/unit/message";
import {TableBodyCell, TableHeadCell} from "components/unit/table";
import {useFormik} from "formik";
import {PageContext} from "components/common/pageContext";


const ScopeListDialog = () => {
    const [open, setOpen] = useState( false);
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
    const [scopeList, setScopeList ] = useState( [{id:0, name: '', description: ''}])

    const context = useContext(PageContext);

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        formik.handleReset({scopeIds: [], clientId: context.id})
        setOpen(false);
    };

    const handleSubmit = () => {
        formik.handleSubmit()
    }

    const formik = useFormik({
        initialValues:{
            scopeIds: []
        },
        onSubmit: (values, {setSubmitting }) => {
            crateScopeMapping( {'clientId':context.id, 'filter' : { 'ids' : values.scopeIds } }).then(response => {
                handleClose()
                //context.actions.setRefreshKey!(utils.generateKey())
                context.actions.setViewOpen(true, context.id)
            })
        }
    });

    useEffect(() => {
        if(open){
            searchScope( {
                executeEvent: 'SEARCH',
                filter : {
                    pageIndex: 0,
                    pageSize: 100,
                }
            }).then(response => {
                setScopeList(response as [])
            })
        }
    }, [open]);

    return (
       <>
            <Button variant="outlined" onClick={handleClickOpen('paper')}>
                <Message id={'ME00012'}/>
            </Button>

           <Button variant="outlined" onClick={() => context.actions.setViewOpen(false)}>
               <Message id={'ME00055'}/>
           </Button>


            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle id="scroll-dialog-title">Select Scope</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                        component="div"
                    >
                        <form>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{background:'#f7f7f7', borderTopStyle:'solid', borderTopColor:'#fac84b'}}>
                                        <TableCell  align="left" variant="head"  width={"20"} padding="checkbox"></TableCell>
                                        <TableHeadCell text={'API'} width={"150"}/>
                                        <TableHeadCell text={'Description'} width={"300"}/>
                                    </TableRow>
                                </TableHead>

                                { scopeList && scopeList.length > 0 ? (
                                    <TableBody>
                                            {
                                                scopeList.map((scope, index) => {
                                                    return (
                                                        <TableRow  key={'body_row_'+ index}>
                                                            <TableCell align="left" variant="body" padding="checkbox">
                                                                <Checkbox name="scopeIds" value={scope.id} onChange={formik.handleChange} />
                                                            </TableCell>
                                                            <TableBodyCell text={scope.name}  width={"200"}/>
                                                            <TableBodyCell text={scope.description}  width={"300"}/>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                    </TableBody>
                                    ) :  (
                                        <TableBody>
                                            <TableBodyCell text={'등록된 Scope 가 없습니다.'}/>
                                            <TableBodyCell text={''}/>
                                        </TableBody>
                                    )
                                }
                            </Table>
                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>OK</Button>
                </DialogActions>
            </Dialog>
       </>
    );
}

export default ScopeListDialog