import request from "../utils/request";
import {searchParam} from "../components/page/search";

const clientUrl = '/client'
const userUrl = '/user'
const scopeUrl = '/scope'
const scopeMappingUrl = '/scopeMapping'

export const loginAction = async (searchParam: {}) => {
    return request.post('/v2/login', searchParam)
}

export const getPublicKey = async () => {
    return request.get('v2/publicKey')
}

export const searchClient = async (searchParam:searchParam) => {
    return request.post(clientUrl, searchParam, 'lists');
}

export const crateClient = async (inputData: {}) => {
    return request.post(clientUrl, {executeEvent:"CREATE", inputData})
}

export const updateClient = async (inputData: {}) => {
    return request.post(clientUrl, {executeEvent:"UPDATE", filter : {"by" : "all"}, inputData})
}

export const updateSecret = async (inputData: {}) => {
    return request.post(clientUrl, {executeEvent:"UPDATE", filter : {"by" : "secret"}, inputData})
}

export const getClient = async (id:string) => {
    return request.post(clientUrl, {executeEvent:"GET", id:id})
}

export const deleteClient = async (id:string) => {
    return request.post(clientUrl, {executeEvent:"DELETE", id:id})
}

export const searchScope = async (searchParam:searchParam) => {
    return request.post(scopeUrl, searchParam, 'lists')
}

export const crateScope = async (inputData: {}) => {
    return request.post(scopeUrl, {executeEvent:"CREATE", inputData})
}

export const crateScopeMapping = async (inputData: {}) => {
    return request.post(scopeMappingUrl, {executeEvent:"CREATE", inputData})
}

export const deleteScope = async (inputData: {}) => {
    return request.post(scopeUrl, {executeEvent:"DELETE", inputData})
}

export const deleteScopeMapping = async (inputData: {}) => {
    return request.post(scopeMappingUrl, {executeEvent:"DELETE", inputData})
}

export const getUser = async (id:number|string) => {
    return request.post(userUrl, {executeEvent:"GET", id:id})
}

export const getUserForFetch = async (userData:{targetID:string, type:string}) => {
    return request.post(userUrl, {"executeEvent" : "GET", "filter" : {"by" : userData.type, "value" : userData.targetID}})
}

export const searchUser = async (searchParam:searchParam) => {
    return request.post(userUrl, searchParam)
}
