import React from "react";
import Page from "../../components/page/page";
import {Box} from "@mui/material";


function AP10230() {

    return (
        <Page title_description={"to set the administrator."}>
            <Box component="div" sx={{m:1}}>
               TODO.. 관리자 설정을 위한 Page
            </Box>
        </Page>
    );
}

export default AP10230;