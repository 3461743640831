import * as React from 'react';
import {headStyles, infoStyles} from "../../assets/jss/unitStyle";
import {Grid} from "@mui/material";
import Message from "./message";

interface gridProp {
    children?: React.ReactNode,
    text?:string|number,
    xs?:number
}

export function GridHead({text, children, xs}:gridProp) {

    // text Value first
    return (
        <Grid  item xs={xs} sx={headStyles}>
            {text ? <Message id={text as string}/> : children}
        </Grid>
    );
}

export function GridInfo({text, children, xs}:gridProp) {
    return (
        <Grid  item xs={xs} sx={infoStyles} alignItems="center"  justifyContent="center">{text ? text : children}</Grid>
    );
}