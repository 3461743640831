import React, {ChangeEvent, useContext} from "react";
import {Box, Grid, Pagination, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {listPagination} from "assets/jss/unitStyle";
import Message from "components/unit/message";
import {listProp} from "components/property/listColumnProp";
import {PageContext} from "components/common/pageContext";


const ListCustom =({columns,resultData}:listProp) => {
    const context = useContext(PageContext);
    const pageSet =(event:ChangeEvent<any>, page:number)=> {
        context.actions.setListDate({
            executeEvent: 'SEARCH',
            filter: {
                pageIndex: (page - 1) * 10,
                pageSize: 10,
            }
        });
    }

    const onclickDetail = (targetCode:string) => {
        context.actions.setViewOpen(true, targetCode)
    }

    return (
        <>
            <Table sx={{ minWidth: 500 }}>
                <TableHead>
                    <TableRow sx={{background:'#f7f7f7', borderTopStyle:'solid', borderTopColor:'#fac84b'}}>
                        {
                            columns.map((row, index) => (
                            <TableCell  align="left" variant="head"  width={row.width}  key={ 'head_'+ index}>
                                <Message id={row.field} />
                            </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resultData.lists ? resultData.lists.map((row, index) => (
                        <TableRow className="tableRow" sx={{height:10}} onClick={ () => onclickDetail(row['id'])} key={ 'body_row_'+ index}>
                            {
                                columns.map((head,subIndex) => {
                                    return (
                                        <TableCell align="left" variant="body" key={ 'cell_'+ subIndex}>
                                            <Box sx={{width: head.width, overflow:"hidden", textOverflow:"ellipsis"}}>
                                                {row[head.field]}
                                            </Box>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    )) :
                        <TableRow  sx={{height:10}}>
                            <TableCell align="left" variant="body" colSpan={columns.length} >
                                    <Message id={"ME10019"}/>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <Box sx={{mt:1}}></Box>
            <Grid  container justifyContent="center" width="100vh">
                <Box sx={{width:"100vh"}}>
                    <Pagination sx={listPagination} count={resultData.count} variant="outlined" shape="rounded" showFirstButton showLastButton
                                onChange={ (e, page)=> pageSet(e,page) }/>
                </Box>
            </Grid>
        </>
    );
}

export default ListCustom;