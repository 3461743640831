import React, {useEffect} from 'react';
import {useLocation, useNavigate, useRoutes} from 'react-router-dom';
import routes, {setRoutes} from './routes';
import {navItem, setNavItem, setSelectNaveItem} from "./modules/slicess/navItemSlice";
import {useDispatch} from "react-redux";
import {defaultMenus} from "./views/layOut/navBar/navBarList";

// 라운팅 설정 V6.
function App() {
    const routing = useRoutes(routes)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        console.log('APP Start')
        // TODO set from DB
        // 라우터 설정.
        setRoutes()
        // LNB 메뉴 설정.
        dispatch(setNavItem(defaultMenus))

        // 직접 주소에 치고 들어오는 케이스 처리
        if(location.pathname.indexOf('/AP') > -1 && location.pathname.length === 8){
            const selectItem:navItem = {
                menuCode: location.pathname.split("/")[1],
                treeOpen:true,
                router:true,
                depth:2,
            }

            dispatch(setSelectNaveItem(selectItem));
            navigate(location.pathname, { replace: true })
        }

        return () => {
            console.log('APP End');
        };
    }, []);
  return (
      <>
        {routing}
      </>
  );
}

export default App;