import {TextField} from "@mui/material";
import * as React from "react";
import {InputProps} from "@mui/material/Input/Input";


export interface TextFieldProps {
    name:string,
    label:string,
    onChange:{
                (e: React.ChangeEvent<any>): void;
                <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    },
    value:string|number
    disabled?:boolean
}

export default function TextFieldUnit({name, label, onChange, value, disabled}:TextFieldProps) {
    return (
        <TextField name={name} label={label} size="small" variant="standard" fullWidth disabled={disabled} onChange={onChange} value={value}/>
    );
}
/// disabled

