import React, {useEffect, useState} from "react";

import Page from "components/page/page";
import {context, initSearchParam} from "components/property/contextApiProp";
import {searchUser} from "../../services/apiService";
import {PageContext} from "components/common/pageContext";
import ListCustom from "components/list/listCustom";
import {columns_10020, listData} from "../../components/property/listColumnProp";
import UserView from "../user/userView";
import {searchParam} from "../../components/page/search";
import {Box, IconButton, TextField} from "@mui/material";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';


function AP10030() {

    const initListData:listData = {
        lists : [],
        count : 0
    }

    const [props, setProps] = useState({
        viewOpen:false,
        editOpen: false,
        id:'',
        searchParam: {...initSearchParam},
        listData : initListData,
        alertDialog : {open:false, title:'ME00053', content:'ME00053'}
    })

    const ap10030:context = {
        viewOpen : props.viewOpen,
        editOpen: false,
        id: props.id,
        searchParam: props.searchParam,
        listData: {lists:[], count:0},
        actions : {
            setViewOpen: (open, id)=> {
                setProps( {...props, viewOpen:open, id:id ? id : ''})
            },
            setListDate: (searchParam)=> {
                searchListData(searchParam as searchParam)
            }
        }
    }

    const searchListData =  (searchParam?:searchParam) => {
         searchUser(searchParam? searchParam : props.searchParam).then(response => {
            const data = response as listData
            setProps( {...props,
                searchParam: searchParam? searchParam : props.searchParam,
                listData: {lists:data.lists, count: data.count}})
        })
    }

    useEffect(() => {
        searchListData(props.searchParam)
    },[]);

    // 필요한 버튼 설정

    const onChange =(e:React.ChangeEvent<any>)=>{
        e.preventDefault()
        const searchCondition = {
            ...props.searchParam , filter : {
                pageIndex: props.searchParam.filter.pageIndex,
                pageSize:props.searchParam.filter.pageSize,
                value: e.target.value
            }
        }
        setProps( {...props, searchParam: searchCondition})
    }

    const onKeyDown =(e:React.KeyboardEvent<any>)=>{
        //e.preventDefault();
        if(e.key === 'Enter'){
            searchListData()
        }
    }

    return (
        <>
            <PageContext.Provider value={ap10030}>
                <Page title_description={"A list of all users."}>
                    <Box sx={{m:1}}>
                    <TextField label="name search" variant="outlined"  size="small"
                               onChange={(e)=>onChange(e)}
                               value={props.searchParam.filter.value}
                               onKeyDown={(e)=>onKeyDown(e)}
                    />
                        <IconButton onClick={()=> searchListData()} color="info">
                            <SearchTwoToneIcon fontSize="medium"/>
                        </IconButton>
                    </Box>

                    <ListCustom  columns={columns_10020} resultData={props.listData}></ListCustom>
                    <UserView/>
                </Page>
            </PageContext.Provider>
        </>
    );
}

export default AP10030;