
interface columnsOptions {
    field : string|any,
    width : string|number
}

export interface listData {
    lists : Array<any>,
    count : number
}

export interface listProp {
    columns: columnsOptions[],
    resultData: listData
}

// 테이블 헤더
export const columns_10020: columnsOptions[] = [
    { field: 'id', width: 120 },
    { field: 'name', width: 150 },
    { field: 'companyId', width: 120 },
    { field: 'userKey', width: 610 },
    // { field: 'createDate', width: '120'},
];


// 테이블 헤더
export const columns_10500: columnsOptions[] = [
    { field: 'id', width: 120 },
    { field: 'title', width: 400 },
    { field: 'nickname', width: 120 },
    { field: 'regDt', width: 210 },
    // { field: 'createDate', width: '120'},
];


// 테이블 헤더에 맞는 데이터를 가지고 오기위한 Key Set
export interface DataOptions_10020 {
    [index: string]: string,        // index Signature
    id:string,
    name:string,
    companyId:string,
    userKey:string,
   // createDate:string
}


export interface DataOptions_10110 {
    [index: string]: string,        // index Signature
    userCode:string,
    name:string,
    e:string,
    p:string,
    q:string,
    r:string,
    s:string,
    t:string,
    u:string,
    v:string,
    w:string,
    x:string
}