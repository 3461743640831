import * as React from 'react';

import LoginPage from './views/login/loginPage'
//import {RouteObject} from "react-router/lib/router";
import {defaultMenus} from "./views/layOut/navBar/navBarList";
import {navItem} from "./modules/slicess/navItemSlice";
import {menuComponents} from "./views/menuCase/menuList";
import Index from "./views/layOut";
import {RouteObject} from "react-router-dom";
import NotFoundView from "./views/errors/notFoundView";
import AP10300 from "./views/menuCase/AP10300";

const subRoutes: RouteObject[] = [
    //{ path: '/login', element: <LoginPage /> },
    { path: '/', element: <AP10300 /> },
    { path: '*', element: <NotFoundView /> },

   // { path: '/AP10040', element: <AP10040 /> },
]


// 라우터 등록....
const menuRecursiveInfoSet = (item:navItem) => {
    if (item.children) {
        item.children.forEach( item => {
            if (item.children){
                menuRecursiveInfoSet(item)
            }
            if (item.router) {
                subRoutes.push({path: item.menuCode, element: menuComponents.get(item.menuCode)})
            }
        })
    }else{
        if (item.router) {
            subRoutes.push({path: item.menuCode, element: menuComponents.get(item.menuCode)})
        }
    }
}

export const setRoutes = ()=> {
    // 모든 라우트 셋팅
    defaultMenus.forEach( item => {
        // 하위 모든 서브 메뉴 리컬시브!!
        menuRecursiveInfoSet(item)
    })
}

const routes: RouteObject[] = [
    {
        path: '/login',
        element: <LoginPage/>
    },

    {
        path: '/aaa',
        element: <NotFoundView/>
    },
    {
        path: '/',
        element: <Index/>,
        children:  subRoutes
    }
    ,{ path: '*', element: <NotFoundView /> }
]

export default routes;