import React, {useContext, useEffect, useRef} from 'react'
import {Box, Button, ButtonGroup, Divider, Drawer, Grid, IconButton, TextField, Typography} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {dividerStyle, headStyles, infoStyles, scopeDrawerStyles} from "../../assets/jss/unitStyle";
import Message from "components/unit/message";
import {PageContext} from "components/common/pageContext";
import {ButtonProperty} from "components/list/listButtom";
import {useFormik} from "formik";
import TextFieldUnit from "components/unit/formUnit";
import {crateScope} from "services/apiService";

const ScopeSave = () => {
    const classes = scopeDrawerStyles();
    const context = useContext(PageContext);
    const initForm = {
                        name: ''
                        ,scopeType: 1
                        ,description: ''
                        ,sortKey: ''
    }

    const formik = useFormik({
        initialValues: initForm,
        onSubmit: (values) => {
            crateScope(values).then(response => {
               context.actions.setListDate()
            })
        }
    });

    useEffect(() => {
        if(context.editOpen){
            formik.setValues(initForm)
        }
    }, [context.editOpen]);

    const drawerRef = useRef<any>(null);

    const buttons:ButtonProperty[] = [
        {
            title : 'ME00077',
            onClick :  () => {
                formik.handleSubmit()
            }
        },
        {
            title : 'ME00016',
            onClick :  () => {
                context.actions.setEditOpen!(false)
            }
        }
    ]

    return (
        <>
            <React.Fragment key='right'>
                <Drawer
                    open={context.editOpen}
                    variant="persistent"
                    anchor="right"
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ref={drawerRef}
                >
                    <form>
                        <Box sx={{m:1}}>
                            <Typography variant="h6" component="span" >
                                <IconButton onClick={()=>context.actions.setEditOpen!(false)}>
                                    <ClearIcon fontSize="medium" />
                                </IconButton>
                                <Message id="ME00056"/>
                            </Typography>
                        </Box>
                        <Divider sx={dividerStyle}/>
                        <Box>
                            <Box sx={{ m:1, lineHeight:3}}>
                                <Grid container>
                                    <Grid  item xs={3} sx={headStyles}>
                                        ScopeID <Box component="span" sx={{color:'red'}}>*</Box>
                                    </Grid>
                                    <Grid  item xs={9} sx={infoStyles}>
                                        <TextField
                                            variant="standard"
                                            label="scope id"
                                            defaultValue="자동으로 시퀀스 처리 됩니다."
                                            disabled
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid  item xs={3} sx={headStyles}>
                                        <Message id="ME00057"/>
                                    </Grid>
                                    <Grid  item xs={9} sx={infoStyles}>
                                        <TextFieldUnit name="name"  label="api name" onChange={formik.handleChange} value={formik.values.name}/>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid  item xs={3} sx={headStyles}>
                                        <Message id="ME00058"/>
                                    </Grid>
                                    <Grid  item xs={9} sx={infoStyles}>
                                        <TextFieldUnit name="description" label="해당 Scope 대한 설명을 작성해주세요." onChange={formik.handleChange} value={formik.values.description}/>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid  item xs={3} sx={headStyles}>
                                        <Message id="ME00059"/>
                                    </Grid>
                                    <Grid  item xs={9} sx={infoStyles}>
                                            <TextFieldUnit name="sortKey" label="정렬순서" onChange={formik.handleChange} value={formik.values.sortKey}/>
                                        </Grid>
                                    </Grid>

                            </Box>
                            <Divider sx={dividerStyle}/>

                            <Grid container justifyContent="center">
                                <ButtonGroup sx={{m: 2}} size="large" variant="outlined" aria-label="button">
                                    {
                                        buttons.map((item, key) => {
                                            return (
                                                <Button onClick={item.onClick} key={key}>
                                                    <Message id={item.title}/>
                                                </Button>
                                            )
                                        })
                                    }
                                </ButtonGroup>
                            </Grid>
                        </Box>
                    </form>
                </Drawer>
            </React.Fragment>
        </>
    )
}

export default ScopeSave