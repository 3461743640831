import axios from 'axios';
import Cookies from "universal-cookie";

const cookie = new Cookies();
const baseURL = process.env.REACT_APP_SERVER_URL;
const setHeader =()=>{
	return {
		'Content-Type': 'application/json'
		, 'Authorization': "Bearer " + cookie.get('access_token')
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {

	goLoginPage : function (){
		cookie.remove('access_token')
		cookie.remove('refresh_token')
		window.location.href = '/';
	},
	post: function (path:string, requestData:{}, index?:string) {
		try {
			//const self = this;
			return new Promise(function (resolve) {

				axios.post(baseURL +path, requestData,
					{headers : setHeader()})
					.then(response => {
						if(response.status === 200) {
							index ? resolve(response.data[index]) : resolve(response.data);
						}
					}).catch(function (error) {
						console.log(error)
						if(error.response.status === 500){
							// alert('Server is problem')
							// self.goLoginPage()
						}else if(error.response.status === 401){
							//  TODO refresh_token 새로 시도 해보고!
							alert('re login!!')
							cookie.remove('access_token')
							cookie.remove('refresh_token')
							window.location.href = '/';
							//resolve('Unauthorized')
							// self.goLoginPage()
						}
					});
			});
		}
		catch (e) {
			console.log(e);
		}
	},

	get: function (url:string) {
		return axios.create({
			baseURL: baseURL, // 기본 서버 주소 입력
			headers: {
				'Content-Type': 'application/json'
				//,'Authorization': "Bearer " + cookie.get('access_token')
			}
		}).get(url)
	}
};