import React from 'react';
import {
  Box, Card, CardContent,
  Typography,
} from '@mui/material';
import {makeStyles} from "@mui/styles";
//import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
      <Box
        display="flex"
        flexDirection="column"
        height="95vh"
        justifyContent="center"
        alignItems="center"
      >
        <Card variant="outlined">
          <CardContent>
          <Typography
            align="center"
            color="textPrimary"
            variant="h2"
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle1"
            sx={{m:2}}
          >
            I'm trying.. u...u
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}
              src="/undraw_page_not_found_su7k.svg"
            />
          </Box>
          </CardContent>
        </Card>
      </Box>
  );
};

export default NotFoundView;
