import {GridColDef} from "@mui/x-data-grid";


export const clientColumns: GridColDef[] = [
    { field: 'id', headerName: 'ClientID', width: 200, description: 'client id', sortable: false},
    {
        field: 'name',
        headerName: 'Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 200,
    },
    { field: 'authorizedGrantTypes', headerName: 'AuthorizedGrantTypes', width: 350, sortable: false },
    { field: 'redirectUri', headerName: 'RedirectUri', width: 280, sortable: false },
    { field: 'accessTokenValidity', headerName: 'AccessTokenValidity', width:250, sortable: false },
    { field: 'refreshTokenValidity', headerName: 'RefreshTokenValidity', width:250, sortable: false }
    // { field: 'createDate', headerName: 'createDate', width: 200}
];

export const userColumns: GridColDef[] = [
    { field: 'id', headerName: 'userCode', width: 200, description: 'user',  sortable: false},
    { field: 'name', headerName: 'name', width: 200, sortable: false },
    { field: 'companyId', headerName: 'companyCode', sortable: false, width: 200, },
    { field: 'userKey', headerName: 'userKey', width: 1000, sortable: false }
    // { field: 'createDate', headerName: 'createDate', width: 200}
];


export const scopeColumns: GridColDef[] = [
    { field: 'id', headerName: 'ScopeID', width: 100},
    { field: 'name', headerName: 'Name', sortable: false, width: 350,},
    { field: 'description', headerName: 'Description', width: 400, sortable: false },
    { field: 'sortKey', headerName: 'Sort', width: 200, sortable: false },
    // { field: 'createDate', headerName: 'createDate', width: 200}
];