import React, {useContext, useEffect, useState} from 'react'
import {Box, Button, ButtonGroup, Divider, Drawer, Grid, IconButton, Typography} from "@mui/material";
import {dividerStyle, drawerStyles, grayStyle} from "assets/jss/unitStyle";
import Message from "components/unit/message";
import {PageContext} from "components/common/pageContext";
import {ButtonProperty} from "components/list/listButtom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {GridHead, GridInfo} from "components/unit/grid";
import {getUser} from "services/apiService";
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import KeyOffTwoToneIcon from '@mui/icons-material/KeyOffTwoTone';

export interface userProp {
                                id: number,
                                name: string,
                                companyId: string,
                                email: string,
                                userKey: string,
                                recordKey: string,
                                createDate: string,
}

const UserView = () => {
    const context = useContext(PageContext);

    const [user, setUser] = useState({
                                                    id: 0,
                                                    name: "",
                                                    companyId: "",
                                                    email: "",
                                                    userKey: "",
                                                    recordKey: "",
                                                    createDate: "",
    })

    const buttons:ButtonProperty[] = [
        {
            title : 'ME00054',
            onClick :  () => {
                context.actions.setViewOpen(false)
            }
        }
    ]

    useEffect(() => {
        if(context.viewOpen) {
            getUser(context.id).then(response => {
                setUser(response as userProp)
            })
        }

    }, [context.id, context.viewOpen]);

    return (
        <>
            <React.Fragment key='right'>
                <Drawer
                    open={context.viewOpen}
                    variant="persistent"
                    anchor="right"
                    classes={{paper: drawerStyles().drawerPaper}}
                >
                    <Box sx={{m:1}}>
                        <Typography variant="h6" component="span" >
                            <IconButton onClick={()=>context.actions.setViewOpen(false)}>
                                <ArrowCircleRightIcon fontSize="medium" />
                                <Box> User Detail</Box>
                            </IconButton>

                        </Typography>
                    </Box>
                    <Divider sx={dividerStyle}/>

                    <Box>
                        <Box sx={{ m:1, lineHeight:3}}>
                            <Grid container>
                                <GridHead text={"ME00060"} xs={2}/>
                                <GridInfo text={user.id} xs={4}/>
                                <GridHead text={"ME00061"} xs={2}/>
                                <GridInfo text={user.createDate} xs={4}/>
                            </Grid>

                            <Divider sx={grayStyle}/>
                            <Grid container>
                                <GridHead text={"ME00062"} xs={2}/>
                                <GridInfo text={user.name} xs={10}/>
                            </Grid>

                            <Divider sx={grayStyle}/>
                            <Grid container>
                                <GridHead text={"ME00063"} xs={2}/>
                                <GridInfo text={"****************************"} xs={10}/>
                            </Grid>

                            <Divider sx={grayStyle}/>
                            <Grid container>
                                <GridHead text={"ME00076"} xs={2}/>
                                <GridInfo text={"e**mail@****.***"} xs={7}/>
                                <GridInfo xs={3}>
                                        <IconButton>
                                            <KeyOffTwoToneIcon fontSize="small"/>
                                        </IconButton>
                                </GridInfo>
                            </Grid>

                            <Divider sx={grayStyle}/>
                            <Grid container>
                                <GridHead text={"ME00064"} xs={2}/>
                                <GridInfo xs={7}>
                                    {user.userKey}
                                </GridInfo>
                                <GridInfo xs={3}>
                                    <CopyToClipboard text={user.userKey}>
                                        <IconButton>
                                            <ContentCopyTwoToneIcon fontSize="small"/>
                                        </IconButton>
                                    </CopyToClipboard>
                                </GridInfo>
                            </Grid>
                            <Divider sx={grayStyle}/>
                            <Grid container>
                                <GridHead text={"ME00065"} xs={2}/>
                                <GridInfo text={user.recordKey} xs={10}/>
                            </Grid>
                        </Box>
                        <Divider sx={dividerStyle}/>

                        <Grid container justifyContent="center">
                            <ButtonGroup sx={{m: 2}} size="large" variant="outlined" aria-label="button group">
                                {
                                    buttons.map((item, key) => {
                                        return (
                                            <Button onClick={item.onClick} key={key}>
                                                <Message id={item.title}/>
                                            </Button>
                                        )
                                    })
                                }
                            </ButtonGroup>
                        </Grid>
                    </Box>
                </Drawer>
            </React.Fragment>
        </>
    )
}
export default UserView