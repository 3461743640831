import React, {useEffect, useState} from "react";
import Page from "components/page/page";
import {columns_10500, listData} from "components/property/listColumnProp";
import ListCustom from "components/list/listCustom";
import {context, initSearchParam} from "components/property/contextApiProp";
import {PageContext} from "components/common/pageContext";
import {searchBoardParam, searchParam} from "components/page/search";
import {searchBoard} from "services/forumService";

function AP10500() {

    const initListData:listData = {
        lists : [],
        count : 0
    }

    const [props, setProps] = useState({
        viewOpen:false,
        editOpen: false,
        id:'',
        searchParam: {...initSearchParam},
        listData : initListData,
        alertDialog : {open:false, title:'ME00053', content:'ME00053'}
    })

    const searchListData =  (searchParam?:searchParam) => {
        searchBoard(searchParam? searchParam : props.searchParam).then(response => {
            const data = response as listData
            console.log("data")

            setProps( {...props,
                searchParam: searchParam? searchParam : props.searchParam,
                listData: {lists:data.lists, count: data.count}})
        })
    }

    useEffect(() => {
        searchListData(props.searchParam)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const ap10500:context = {
        viewOpen : props.viewOpen,
        editOpen: false,
        id: props.id,
        searchParam: props.searchParam,
        listData: {lists:[], count:0},
        actions : {
            setViewOpen: (open, id)=> {
                setProps( {...props, viewOpen:open, id:id ? id : ''})
            },
            setListDate: (searchParam)=> {
                searchListData(searchParam as searchParam)
            }
        }
    }

    return (
        <PageContext.Provider value={ap10500}>
        <Page title_description={"Comment type notice board"}>
            <ListCustom  columns={columns_10500} resultData={props.listData}></ListCustom>
        </Page>
        </PageContext.Provider>
    );
}

export default AP10500;