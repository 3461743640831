import React, {useContext, useEffect} from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Divider,
    Drawer,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import {clientStyles, dividerStyle} from "assets/jss/unitStyle";
import Message from "components/unit/message";
import {PageContext} from "components/common/pageContext";
import {ButtonProperty} from "components/list/listButtom";
import {FormikValues, useFormik} from "formik";
import TextFieldUnit from "components/unit/formUnit";
import {crateClient, getClient, updateClient} from "services/apiService";
import {GridHead, GridInfo} from "components/unit/grid";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

interface clientSaveProp {
    code:number,
    id: string,
    //secret: string,
    name: string,
    authorizedGrantTypes: string,
    arrAuthorizedGrantTypes: string[],
    redirectUri: string,
    accessTokenValidity: number,
    refreshTokenValidity: number,
    allowedIps: string,
}

const ClientSave = () => {
    const classes = clientStyles();
    const context = useContext(PageContext);
    const initForm = {
                                                id: ''
                                                ,name: ''
                                                ,authorizedGrantTypes: ''
                                                ,arrAuthorizedGrantTypes: new Array<string>()
                                                ,redirectUri: ''
                                                ,accessTokenValidity: 300
                                                ,refreshTokenValidity: 3600
                                                ,allowedIps:''
    }

    useEffect(() => {
        if(context.editOpen && context.id){
            getClient(context.id).then(response => {
                const client = response as clientSaveProp;
                client.arrAuthorizedGrantTypes =  client.authorizedGrantTypes.split(',')
                formik.setValues(client)
            })
        }else{
            formik.setValues(initForm)
        }
    }, [context.editOpen, context.id]);

    const formik = useFormik({
        initialValues: initForm,
        onSubmit: (values) => {
            if(validate(values)){
                return;
            }
            values.authorizedGrantTypes = values.arrAuthorizedGrantTypes.join(',')

            if(context.id){
                updateClient(values).then( response => {
                    context.actions.setViewOpen(true,  context.id)
                })
            }else{
                crateClient(values).then(response => {
                    const data = response as clientSaveProp;
                    if(data.code === 200){
                        context.actions.setViewOpen(true,  data.id)
                    }else{
                        context.actions.setAlertDialog!('ME10020')
                    }
                })
            }

        }
    });

    const checkBox =(checkedValue:string)=>{
        return formik.values.arrAuthorizedGrantTypes.some( types => {
            return types === checkedValue;
        })
    }

    const validate =(values:FormikValues)=>{
        if(!values.id /*|| !Number(values.id)*/){
            context.actions.setAlertDialog!('ME10012')
            return true
        }

        if(!values.name){
            context.actions.setAlertDialog!('ME10013')
            return true
        }

        if(values.arrAuthorizedGrantTypes.length === 0){
            context.actions.setAlertDialog!('ME10014')
            return true
        }

        if(!values.redirectUri){
            context.actions.setAlertDialog!('ME10015')
            return true
        }

        return false
    }


    const buttons:ButtonProperty[] = [
        {
            title : 'ME00077',
            onClick :  () => {
                formik.handleSubmit()
            }
        },
        {
            title : 'ME00016',
            onClick :  () => {
                context.actions.setEditOpen!(false)
            }
        }
    ]

    return (
        <>
            <React.Fragment key='right'>
                <Drawer
                    open={context.editOpen}
                    variant="persistent"
                    anchor="right"
                    classes={{paper: classes.drawerPaper}}
                >
                    <form>
                        <Box sx={{m:1}}>
                            <Typography variant="h6" component="span" >
                                <IconButton onClick={()=>context.actions.setEditOpen!(false)}>
                                    <CancelTwoToneIcon fontSize="medium" />
                                    <Box>Client Reg</Box>
                                </IconButton>

                            </Typography>
                        </Box>
                        <Divider sx={dividerStyle}/>
                        <Box>
                            <Box sx={{ m:1, lineHeight:3}}>
                                <Grid container>
                                    <GridHead text={"ME00074"} xs={3}/>
                                    <GridInfo xs={9}>
                                        <TextFieldUnit disabled={!!context.id} name="id"
                                                       label="고객 코드을 입력합니다."
                                                       onChange={formik.handleChange} value={formik.values.id}/>
                                    </GridInfo>
                                </Grid>

                                <Grid container>
                                    <GridHead text={"ME00075"} xs={3}/>
                                    <GridInfo xs={9}>
                                        <TextFieldUnit name="name"  label="고객 이름을 입력합니다." onChange={formik.handleChange} value={formik.values.name}/>
                                    </GridInfo>
                                </Grid>

                                <Grid container>
                                    <GridHead xs={3}>
                                        <Tooltip title="토큰 발급을 위한 인증방식을 선택합니다." placement="bottom-end">
                                            <Box><Message id={"ME00068"}/></Box>
                                        </Tooltip>
                                    </GridHead>
                                    <GridInfo xs={9}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox name="arrAuthorizedGrantTypes" checked={checkBox("ocare_authorization_code")}
                                                                                 onChange={formik.handleChange} value="ocare_authorization_code"/>} label="오케어 권한 승인 코드 방식" />
                                            <FormControlLabel control={<Checkbox name="arrAuthorizedGrantTypes"
                                                                                 checked={checkBox("authorization_code")}
                                                                                 onChange={formik.handleChange} value="authorization_code" />} label="권한 승인 코드 방식" />
                                            <FormControlLabel control={<Checkbox name="arrAuthorizedGrantTypes"
                                                                                 checked={checkBox("client_credentials")}
                                                                                 onChange={formik.handleChange} value="client_credentials"/>} label="클라이언트 자격증명 승인 방식" />
                                            <FormControlLabel control={<Checkbox name="arrAuthorizedGrantTypes"
                                                                                 checked={checkBox("refresh_token")}
                                                                                 onChange={formik.handleChange} value="refresh_token" />} label="토큰 갱신" />
                                        </FormGroup>
                                    </GridInfo>
                                </Grid>
                                <Grid container>
                                    <GridHead xs={3}>
                                        <Tooltip title="인증 완료 후 호출될 주소를 설정합니다." placement="bottom-end">
                                            <Box><Message id={"ME00069"}/></Box>
                                        </Tooltip>
                                    </GridHead>
                                    <GridInfo  xs={9}>
                                        <TextFieldUnit name="redirectUri" label=",(콤마)를 통해서 복수 등록 가능합니다." onChange={formik.handleChange} value={formik.values.redirectUri}/>
                                    </GridInfo>
                                </Grid>
                                <Grid container>
                                    <GridHead  xs={3}>
                                        <Tooltip title="토큰의 유효 기간을 초 단위로 설정합니다." placement="bottom-end">
                                            <Box><Message id={"ME00070"}/></Box>
                                        </Tooltip>
                                    </GridHead>
                                    <GridInfo  xs={3}>
                                        <TextFieldUnit name="accessTokenValidity" label="토큰유효시간(초)" onChange={formik.handleChange} value={formik.values.accessTokenValidity}/>
                                    </GridInfo>
                                    <GridHead xs={3}>
                                        <Box><Message id={"ME00072"}/></Box>
                                    </GridHead>
                                    <GridInfo  xs={3} >
                                        <TextFieldUnit name="refreshTokenValidity" label="리프레쉬 토큰유효시간(초)" onChange={formik.handleChange} value={formik.values.refreshTokenValidity}/>
                                    </GridInfo>
                                </Grid>
                                <Grid container>
                                    <GridHead  xs={3}>
                                        <Tooltip title="클라이언트 자격증명을 이용할 때 허용 IP를 추가합니다." placement="bottom-end">
                                            <Box><Message id={"ME00073"}/></Box>
                                        </Tooltip>
                                    </GridHead>
                                    <GridInfo  xs={9}>
                                        <TextFieldUnit name="allowedIps" label=",(콤마)를 통해서 복수 등록 가능합니다."  onChange={formik.handleChange} value={formik.values.allowedIps}/>
                                    </GridInfo>
                                </Grid>

                            </Box>
                            <Divider sx={dividerStyle}/>
                            <Grid container justifyContent="center">
                                <ButtonGroup sx={{m: 2}} size="large" variant="outlined" aria-label="button">
                                    {
                                        buttons.map((item, key) => {
                                            return (
                                                <Button onClick={item.onClick} key={key}>
                                                    <Message id={item.title}/>
                                                </Button>
                                            )
                                        })
                                    }
                                </ButtonGroup>
                            </Grid>
                        </Box>
                    </form>
                </Drawer>
            </React.Fragment>
        </>
    )
}

export default ClientSave