import React from 'react'

import NavBarItem from './navBarItem'
import {useAppDispatch, useAppSelector} from "modules/store";
import {Box} from "@mui/material";

const NavBar = () => {
    // app.tsx 설정한 navItem 순회
    //const dispatch = useAppDispatch()
    const navItem = useAppSelector(state => state.navItem);

    return (
        <Box id="sideLnb"  sx={{ width :'260px' }}>
            <Box className="v-box">
                <Box className="posi_re flex-1">
                    <Box  className="lnb-container snbCon">
                        {
                            navItem.navItemList.map((item, index) => {
                                return <NavBarItem {...item} key={ item.menuCode+'_'+index}/>
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default NavBar