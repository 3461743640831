import React, {useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'

import 'assets/scss/layout.scss'
import TopBar from "./topBar/topBar";
import NavBar from "./navBar/navBar";
import {useAppSelector} from "modules/store";
// import {navItem, setNavItem, setSelectNaveItem} from "../../modules/slicess/navItemSlice";
// import {defaultMenus} from "./navBar/navBarList";
import Cookies from "universal-cookie";

const Index = () => {

    //1. 내가 권한이 있는 메뉴만 디스패치한다. TODO
    // const loginInfoState  = useAppSelector(state => state.userInfo);
    // const dispatch = useAppDispatch();
    // const location = useLocation();

    const cookie = new Cookies();
    const navigate = useNavigate()
    // 쿠키가 없다면 로그인 페이지로 보낸다잉.

    const [open, setOpen ] = useState(false)

    //const navItem = useAppSelector(state => state.navItem);
    //const user = useAppSelector(state => state.loginUser);


    useEffect( ()=> {
        if(!(cookie.get('access_token'))){
            navigate( "/login",{replace: true })
        }

        setOpen(true)
    }, []) // [user.loginInfo.email]

    return (
        <>
        {open ? (
            <div id="mainWrap">
                <div id="containerWrap">
                    <TopBar />
                    <NavBar />
                    <div id="contents">
                        <Outlet />  {/*라우터 설정*/}
                    </div>
                </div>
            </div>
        ) :  '' }
        </>
    )
}

export default Index