import React, {useEffect, useState} from "react";

import List from "components/list/list";
import Page from "components/page/page";
import {ButtonProperty} from "components/list/listButtom";
import {context, initSearchParam} from "components/property/contextApiProp";
import {getClient, searchClient} from "services/apiService";
import {clientColumns} from "components/list/listHead";
import {PageContext} from "components/common/pageContext";
import ClientView, {clientProp} from "views/client/clientView";
import ClientSave from "views/client/clientSave";
import AlertDialog from "components/unit/alertDialog";


function AP10010() {

    const [props, setProps] = useState({
        viewOpen:false,
        editOpen: false,
        id:'',
        refreshKey: 'refreshKey',
        targetProp: {},
        searchParam: {...initSearchParam},
        listData : {lists:[], count:0},
        alertDialog : {open:false
                        , title:'ME00053'
                        , content:'ME00053'
                        , handleOK: ()=>{}
        }
    })

    const ap10010:context = {
        viewOpen: props.viewOpen,
        editOpen: props.editOpen,
        id: props.id,
        refreshKey: props.refreshKey,
        searchParam: props.searchParam,
        targetProp: props.targetProp as clientProp,
        listData: props.listData,
        actions : {
            setViewOpen: (open, id)=> {
                if(open){
                    getClient(id as string).then(response => {
                        setProps( {...props, editOpen:false, viewOpen:true,
                            targetProp: response as clientProp,
                            id: id as string})
                    })
                }else{
                    setProps( {...props, viewOpen:false})
                }
            },
            setEditOpen: (editOpen, id) => {
                setProps( {...props ,id:id ? id : '', editOpen: editOpen})
            },
            setListDate: (searchParam:any, targetProp?:{}) => {
                searchListData(targetProp)
            },
            setAlertDialog:(content, title, callback)=>{
                setProps({...props,
                        alertDialog : {...props.alertDialog , open:true
                                                            , content:content
                                                            , title:title ? title : 'ME00053'
                                                            , handleOK:callback ? callback : ()=> {
                                                                setProps({...props, alertDialog : {...props.alertDialog, content:content, open:false}});
                                                            }
                                    }
                });
            }
        }
    }

    const searchListData = (targetProp?:{}) => {
        searchClient(props.searchParam).then(response => {
           setProps( {...props, viewOpen: !!targetProp,
               targetProp: targetProp? targetProp as clientProp : props.targetProp,
               listData: {lists :response ? response as [] : [], count: 0}}
           )
        })
    }

    useEffect(() => {
        searchListData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 필요한 버튼 설정
    const buttons:ButtonProperty[] = [
            {
                title : 'ME00014',    // 클라이언트 등록
                onClick :  () => {
                    //ap10010.actions.setEditOpen!(!props.editOpen);
                    //setProps( {...props, editOpen:!props.editOpen})
                    setProps( {...props ,id : '', editOpen: !props.editOpen})
                }
            },
            {
                title : 'ME00019', // 목록 새로 고침
                onClick :  () => {
                    searchListData()
                }
            }
        ]

    return (
        <>
            <PageContext.Provider value={ap10010}>
                <Page buttons={buttons} title_description={"Clients are applications and services that can request authentication of a user."}>
                    <List data={props.listData.lists} columns={clientColumns}  options={{pageSize:25, checkboxSelection: false}}/>
                    <ClientView/>
                    <ClientSave/>

                    <AlertDialog open={props.alertDialog.open}
                                 handleOK={props.alertDialog.handleOK}
                                 handleCancel={()=> { setProps({...props, alertDialog : {...props.alertDialog, open:false}})}}
                                 title={props.alertDialog.title}
                                 content={props.alertDialog.content}
                    />
                </Page>
            </PageContext.Provider>
        </>
    );
}

export default AP10010;