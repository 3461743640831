import React from "react";
import Page from "../../components/page/page";


function AP10040() {
    return (
        <>
            <Page title_description={"group list"}>
                ...
            </Page>
        </>
    );
}

export default AP10040;
