import * as React from "react";
import {FormattedMessage} from "react-intl";

interface MessageProps {
    id: string
    defaultMsg? : string
}

export default function Message({id, defaultMsg='설정된 메세지 내용이 없습니다.'}:MessageProps) {
    return (
        <FormattedMessage id={id} defaultMessage={defaultMsg}/>
    );
    // 215만원
}