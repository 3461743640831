import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Message from "./message";
import {Box, IconButton} from "@mui/material";
import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';


export interface DialogProps{
    open:boolean,
    handleOK:() => void,
    handleCancel?:() => void,
    title:string,
    content:string
}

export default function AlertDialog(props:DialogProps) {
    return (
            <Dialog
                open={props.open}
                //onClose={props.handleClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                //sx={{width:700}}
                fullWidth={true}
                maxWidth={"xs"}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box>
                    <PriorityHighTwoToneIcon fontSize="medium"></PriorityHighTwoToneIcon>
                        <Message id={props.title}/>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Message id={props.content}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    { props.handleCancel ? <Button onClick={props.handleCancel}>cancel</Button> : ''}
                    <Button onClick={props.handleOK} autoFocus>OK</Button>
                </DialogActions>
            </Dialog>
    );
}