import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getUserForFetch} from "../../services/apiService";


export interface login {
    //id:string,
    name:string,
    email:string,
    roles: any[],
    manage:boolean
    //recordKey:string
}

export interface loginState {
    loginInfo : login
}

const initialState:loginState = {
    loginInfo : {
                    name:'',
                    email:'',
                    roles: new Array<{roleName : 'user'}>(),
                    manage: false
                 }
}


export const fetchUserInfo = createAsyncThunk(
    'user/info',
    async (userData:{type:string, targetID:string}) => {
        if( userData.type === 'logout'){
            return { name : '', email : ''}
        }else{
            return getUserForFetch(userData).then(response =>{
                return response;
            })
        }

    }
)

export const loginUserSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
               // console.log( 'pending')
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                const loginData = action.payload as login
                loginData.manage =  loginData.roles ? loginData.roles.some(value =>
                    value.roleName === 'admin'
                ) : false
                state.loginInfo =loginData
            })
            .addCase(fetchUserInfo.rejected, (state) => {
                //console.log("rejected")
            })
    },
})
